import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ customDescription, lang, meta, customTitle, canonical }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { title, description, siteUrl } = data.site.siteMetadata;
        const metaDescription = customDescription || description;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={customTitle}
            titleTemplate={`%s | ${title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: `${customTitle} | ${title}`,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:title`,
                content: `${customTitle} | ${title}`,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `yandex-verification`,
                content: `ae992bb7636a17c8`,
              },
              {
                name: `google-site-verification`,
                content: `7jKv620f914tGCPiIikaJub3awfl9iifOAXWPSuIsP0`,
              },
            ].concat(meta)}
          >
            <link rel="canonical" href={`${siteUrl}${canonical}`} />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `ru`,
  meta: [],
};

SEO.propTypes = {
  customDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  customTitle: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
