import React from 'react';
import styled from 'styled-components';
import Nav from './nav';

const Header = () => (
  <Container>
    <div className="wrapper">
      <header>
        <Nav />
      </header>
    </div>
  </Container>
);


const Container = styled.div`
  background: #273747;
  left: 0;
  right: auto;
  transition: all .2s ease-in;
  height: 100%;
  position: fixed;
  width: 23%;

  .wrapper {
    width: 100%;

    header {
      width: 100%;
      padding: 10% 15%;
      box-sizing: border-box;
    
      @media (max-width: 900px) {
        padding: 10%;
      }
    }

  }

  @media (max-width: 900px) {
    width: 26%;
  }

  @media (max-width: 760px) {
    width: 100%;
    position: initial;
    text-align: center;
  }
`;


export default Header;
