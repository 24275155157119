import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Header from './header';
// import ReactAd from '../components/general/ReactAd';
import * as fonts from '../../fonts';

const theme = {
  red: '#fe2c55',
  black: '#323232',
  grey: '#3A3A3A',
  lightgrey: '#E1E1E1',
  offWhite: '#EDEDED',
  maxWidth: '1200px',
  maxWidthSingle: '840px',
  maxWidthFront: '600px',
  innerPadding: '0 22px',
  bs: '0 12px 24px 0 rgba(0, 0, 0, 0.09)',
};

const StyledPage = styled.div`
  background: white;
  color: #000;
  position: relative;
  min-height: 100%;
`;

const Content = styled.div`
  left: 23%;
  margin-bottom: 0;
  padding-left: 30px;
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
  }

  @media (max-width: 900px) {
    left: 26%;
  }

  @media (max-width: 760px) {
    position: initial;
  }
`;

const GlobalStyle = createGlobalStyle`
html {
box-sizing: border-box;
font-size: 10px;
}
*, *:before, *:after {
box-sizing: inherit;
}
@font-face {
    font-family: 'settle_down';
    font-style: normal;
    font-weight: normal;
    src: local('settle_down'), url('${fonts.SettleDownWebfont}') format('truetype');
  }
  @font-face {
    font-family: 'poppins_bold';
    font-style: normal;
    font-weight: bold;
    src: local('poppins_bold'), url('${fonts.PoppinsBold}') format('truetype');
  }
body {
padding: 0;
margin: 0;
font-size: 18px;
line-height: 1.8;
font-family: 'Open Sans', sans-serif;
font-weight: 400;
}
a {
text-decoration: none;
color: ${theme.black};
}
 
h1 {
  font-size: 32px;
  margin: 0 0 25px 0;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
  color: #444;
  @media (max-width: 800px) {
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
    }
}

h2,h3,h4,h5,h6 {
  font-size: 24px;
  margin: 0 0 25px 0;
  text-align: left;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-style: italic;
  @media (max-width: 800px) {
    font-size: 2rem;
    }
}

h3 {
  font-size: 20px;
}

`;


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data) => {
      return (
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <StyledPage>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: data.site.siteMetadata.description,
                },
              ]}
            >
              <link
                // href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&amp;subset=cyrillic,cyrillic-ext"
                href="https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Poppins:700|Open+Sans:400|Inconsolata:400|Roboto:700italic,400italic&amp;subset=cyrillic,cyrillic-ext"
                rel="stylesheet"
              />
              <link rel="canonical" href="https://stackdev.blog" />
            </Helmet>
            {/* <ReactAd /> */}
            <Header />
            <Content>{children}</Content>
            {/* <Footer /> */}
          </StyledPage>
        </ThemeProvider>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
