import React from 'react';
import { Link } from 'gatsby';
import NavStyles from '../styles/nav-styles';

const Nav = () => (
  <>
    <NavStyles data-test="nav">
    <li>
    <Link to="/">
      <div className="avatar">
          <img src="https://res.cloudinary.com/dlmeqtsfq/image/upload/v1680692823/2023-Stackdev/avatars/yavatar_300.jpg" alt="stackdev.blog articles"/>
        </div>
    </Link>

    <h1>Василий Муравьев</h1>
     
    </li>     
      <li className="courses">
        <div className="courses-count">
          <a href="https://stackdev.ru">Мои Курсы</a>
          <span className="count">8</span>
        </div>
      </li>
      <li>
        <a href="https://www.youtube.com/@stackdev" target="_blank">Youtube</a>
      </li>
      <li>
        <a href="https://t.me/stackdevru" target="_blank">Telegram</a>
      </li>
      <li>
        <Link to="/contacts">Контакты</Link>
      </li>
    </NavStyles>
  </>
);

export default Nav;
