import styled from 'styled-components';

const NavStyles = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 2rem;

  h1 {
      font-size: 22px;
      color: #fff;

    @media only screen and (max-width: 760px) {
      text-align: center;
    }

  }

  li {
    list-style: none;
    padding: 10px;

    @media only screen and (max-width: 760px) {
      text-align: center;
    }

    .count {
      background: rgb(255, 41, 5);
      width: 30px;
      height: 30px;
      display: inline-block;
      border-radius: 100px;
      text-align: center;
      color: #fff;
      font-weight: bold;
      margin-left: 10px;
      line-height: 30px;
    }
  }
  .courses {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 760px) {
      .courses-count {
        max-width: 124px;
        margin: 0 auto;
      }
    }

  }

  .avatar {
    img {
      max-width: 146px;
      border-radius: 50%;
    }
  }
  a {
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size: 18px;
    background: none;
    border: 0;
    cursor: pointer;
    color: #fff;
  }
  @media (max-width: 410px) {
    width: 100%;
    justify-content: center;
  }
`;

export default NavStyles;
